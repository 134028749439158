var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('vue-confirm-dialog'),_c('div',{staticClass:"info-item-wrap"},[_c('div',{staticClass:"profile-info-wrap"},[_c('div',{staticClass:"pic",style:(_vm.imgPath)}),_c('div',{staticClass:"info-header"},[_c('strong',[_vm._v(" "+_vm._s(_vm.requestDetail.seeker)+" ")]),_c('span',[_vm._v(" 성별 : "+_vm._s(_vm.requestDetail.gender)+" / 나이 : "+_vm._s(_vm.requestDetail.age)+" 세 / 경력 : "+_vm._s(_vm.requestDetail.career)+" ")])]),_c('div',{staticClass:"e01"},[_vm._v(" "+_vm._s(((_vm.requestDetail.workingPlace) + " : " + (_vm.requestDetail.jobPositionName) + " - " + (_vm.requestDetail.jobPositionDtlName)))+" ")])]),_c('dl',[_c('dt',[_vm._v("근무 매칭 현황")]),_c('dd',[_c('ul',{staticClass:"info-item-list"},[(_vm.requestDetail.workRequestDt)?_c('li',[_c('div',{staticClass:"value"},[_c('div',{staticClass:"status req"},[_vm._v("근무요청")]),_c('div',{staticClass:"e01"},[_vm._v(_vm._s(_vm.requestDetail.workRequestDt))])])]):_vm._e(),(_vm.requestDetail.workReplyDt)?_c('li',[_c('div',{staticClass:"value"},[_c('div',{staticClass:"status resp"},[_vm._v("근무응답")]),_c('div',{staticClass:"e01"},[_vm._v(_vm._s(_vm.requestDetail.workReplyDt))])])]):_vm._e(),(
              _vm.requestDetail.pushStatus === _vm.PUSH_STATUS[3].value ||
              _vm.requestDetail.pushStatus === _vm.PUSH_STATUS[4].value ||
              _vm.requestDetail.pushStatus === _vm.PUSH_STATUS[5].value
            )?_c('li',[_c('div',{staticClass:"value"},[_c('div',{staticClass:"status ok"},[_vm._v("근무확정")]),(_vm.requestDetail.recruitOkDt)?_c('div',{staticClass:"e01"},[_vm._v(" "+_vm._s(_vm.requestDetail.recruitOkDt)+" ")]):_vm._e()])]):_vm._e(),(_vm.requestDetail.passDt)?_c('li',[_c('div',{staticClass:"value"},[_c('div',{staticClass:"status succ"},[_vm._v("합격확인")]),_c('div',{staticClass:"e01"},[_vm._v(_vm._s(_vm.requestDetail.passDt))])])]):_vm._e(),(_vm.requestDetail.pushStatus === _vm.PUSH_STATUS[5].value)?_c('li',[_c('div',{staticClass:"value"},[_c('div',{staticClass:"status succ"},[_vm._v("취소")]),_c('div',{staticClass:"e01"},[_vm._v(_vm._s(_vm.requestDetail.cancelDt))]),_c('div',{staticClass:"e01"},[_vm._v(_vm._s(_vm.requestDetail.cancelReason))])])]):_vm._e()])]),_c('dt',[_vm._v("근무 정보")]),_c('dd',[_c('ul',{staticClass:"info-item-list"},[_c('li',[_c('div',{staticClass:"key"},[_vm._v("근무기간")]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"e01"},[_vm._v(" "+_vm._s(_vm.requestDetail.workStartDate)+" ~ "+_vm._s(_vm.requestDetail.workEndDate)+" ")])])]),_c('li',[_c('div',{staticClass:"key"},[_vm._v("근무시간")]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"e01"},[_vm._v(" "+_vm._s(_vm.requestDetail.workStartTime)+" ~ "+_vm._s(_vm.requestDetail.workEndTime)+" ")]),_c('button',{staticClass:"status resp",staticStyle:{"margin-left":"20px"},on:{"click":_vm.goWorkingDetailPage}},[_vm._v(" 상세보기 ")])])]),_c('li',[_c('div',{staticClass:"key"},[_vm._v("마감일시")]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"e01",staticStyle:{"color":"#619ef6"}},[_vm._v(" "+_vm._s(_vm.requestDetail.closingDt)+" ")])])])])])])]),_c('div',{staticClass:"info-btn-wrap"},[(
        _vm.requestDetail.pushStatus === _vm.PUSH_STATUS[2].value &&
        _vm.requestDetail.negoYn === 'Y' //&&
        //requestDetail.recruitingStatus === 'RECRUITING'
      )?_c('button',{staticClass:"btn-ok",attrs:{"type":"button"},on:{"click":_vm.clickOk}},[_vm._v(" 구인 확정 ")]):_vm._e(),(_vm.requestDetail.pushStatus !== _vm.PUSH_STATUS[5].value)?_c('button',{staticClass:"btn-cancel",attrs:{"type":"button"},on:{"click":_vm.clickCancel}},[_vm._v(" 취소 ")]):_vm._e(),_c('button',{staticClass:"btn-cancel",attrs:{"type":"button"},on:{"click":_vm.goList}},[_vm._v("목록")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }