<template>
  <li>
    <div class="info-item-wrap">
      <div class="info-header">
        <strong>
          {{ requestDetail.workingPlace }}
          <!-- 신라호텔 -->
        </strong>
        <span>
          {{ requestDetail.workingAddress }}
          <!-- 경기도 부천시 -->
        </span>
      </div>
      <ul class="info-item-list">
        <li>
          <div class="key">
            <img src="~assets/image/ico/ico-work-job.png" />
            <span>직종</span>
          </div>
          <div class="value">
            {{
              `${requestDetail.jobPositionName} ${
                requestDetail.jobPositionDtlName
              } (${insertCommaToNumber(requestDetail.payAmt)}원)`
            }}
            <!-- 룸메이드 (130,000원) -->
          </div>
        </li>
      </ul>
      <dl>
        <dt>근무 매칭 현황</dt>
        <dd>
          <ul class="info-item-list">
            <li v-if="requestDetail.workRequestDt">
              <div class="key">
                <img src="~assets/image/ico/ico-work-biz.png" />
                <span>기업</span>
              </div>
              <div class="value">
                <div class="status req">근무요청</div>
                <div class="e01">{{ requestDetail.workRequestDt }}</div>
              </div>
            </li>
            <li v-if="requestDetail.workReplyDt">
              <div class="key">
                <img src="~assets/image/ico/ico-work-my.png" />
                <span>나</span>
              </div>
              <div class="value">
                <div class="status resp">근무응답</div>
                <div class="e01">{{ requestDetail.workReplyDt }}</div>
              </div>
            </li>
            <li
              v-if="
                requestDetail.pushStatus === PUSH_STATUS[3].value ||
                requestDetail.pushStatus === PUSH_STATUS[4].value ||
                requestDetail.pushStatus === PUSH_STATUS[5].value
              "
            >
              <div class="key">
                <img src="~assets/image/ico/ico-work-biz.png" />
                <span>기업</span>
              </div>
              <div class="value">
                <div class="status ok">근무확정</div>
                <div v-if="requestDetail.recruitOkDt" class="e01">
                  {{ requestDetail.recruitOkDt }}
                </div>
                <!--div v-else class="e01">대기중</div-->
              </div>
            </li>
            <li v-if="requestDetail.passDt">
              <div class="key">&nbsp;</div>
              <div class="value">
                <div class="status succ">합격확인</div>
                <div class="e01">{{ requestDetail.passDt }}</div>
              </div>
            </li>
            <li v-if="requestDetail.pushStatus === PUSH_STATUS[5].value">
              <div
                class="key"
                v-if="requestDetail.cancelUserType === 'EMPLOYER'"
              >
                <img src="~assets/image/ico/ico-work-biz.png" />
                <span>기업</span>
              </div>
              <div class="key" v-else>
                <img src="~assets/image/ico/ico-work-my.png" />
                <span>나</span>
              </div>
              <div class="value">
                <div class="status succ">취소</div>
                <div class="e01">{{ requestDetail.cancelDt }}</div>
                <div class="e01">{{ requestDetail.cancelReason }}</div>
              </div>
            </li>
          </ul>
        </dd>
        <dt>근무 정보</dt>
        <dd>
          <ul class="info-item-list">
            <li>
              <div class="key">근무기간</div>
              <div class="value">
                <div class="e01">
                  {{ requestDetail.workStartDate }} ~
                  {{ requestDetail.workEndDate }}
                </div>
              </div>
            </li>
            <li>
              <div class="key">근무시간</div>
              <div class="value">
                <div class="e01">
                  {{ requestDetail.workStartTime }} ~
                  {{ requestDetail.workEndTime }}
                </div>
                <button
                  class="status resp"
                  style="margin-left: 20px"
                  @click="goWorkingDetailPage"
                >
                  상세보기
                </button>
              </div>
            </li>
            <li>
              <div class="key">마감일시</div>
              <div class="value">
                <div class="e01" style="color: #619ef6">
                  {{ requestDetail.closingDt }}
                </div>
              </div>
            </li>
            <li>
              <div class="key">지원상태</div>
              <div class="value">
                <div class="e01" style="color: #090909; font-weight: bold">
                  {{ applyMessage }}
                </div>
              </div>
            </li>
          </ul>
        </dd>
      </dl>
    </div>
    <div class="info-btn-wrap">
      <!-- @TODO : 상태별 버튼 처리 -->
      <!-- 근무응답, 구인OK, 구인확인 -->
      <!-- 근무요청 상태인 경우 근무응답 버튼 표시 -->
      <button
        v-if="
          requestDetail.pushStatus === PUSH_STATUS[1].value &&
          requestDetail.recruitingStatus === 'RECRUITING'
        "
        type="button"
        class="btn-ok"
        @click="clickResponse"
      >
        근무응답
      </button>
      <!-- 근무확정 상태인 경우 합격확인 버튼 표시-->
      <button
        v-else-if="
          requestDetail.pushStatus === PUSH_STATUS[3].value &&
          requestDetail.negoYn === 'Y'
        "
        type="button"
        class="btn-ok"
        @click="clickCheckPass"
      >
        합격확인
      </button>
      <button
        v-if="requestDetail.pushStatus === PUSH_STATUS[4].value"
        type="button"
        class="btn-cancel"
        @click="clickCancel"
      >
        취소
      </button>
      <button type="button" class="btn-cancel" @click="goList">목록</button>
    </div>
  </li>
</template>

<script>
import { PUSH_STATUS, PUSH_STATUS_NAME } from 'Configs/pushStatus'
import jobDetailMixin from 'Mixins/jobDetail'
import { getRecruitDetail } from 'Api/modules'

export default {
  props: {
    requestDetail: {
      type: Object,
    },
  },
  data() {
    return {
      PUSH_STATUS: Object.freeze(PUSH_STATUS),
      PUSH_STATUS_NAME: Object.freeze(PUSH_STATUS_NAME),
      pushStatus: '',
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.fetchJobDetail()
      }
    },
  },
  mixins: [jobDetailMixin],
  computed: {
    applyMessage() {
      // if (!this.applyText) return '지원불가' //기본값
      //console.info('this.pushStatus', this.pushStatus)
      if (this.requestDetail.pushStatus == '') {
        return '근무 매칭이 되지 않았습니다.'
      } else {
        if (this.requestDetail.recruitingStatus == 'CLOSE') {
          //마감 된 경우
          //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
          if (this.requestDetail.pushStatus === 'SCALL') {
            //근무요청o
            return '근무 요청을 하였으나 지원하지 않았습니다.'
          }
          if (this.requestDetail.pushStatus === 'ROK') {
            //근무응답o
            return '지원을 하였으나 합격이 되지 않았습니다.'
          }
          if (this.requestDetail.pushStatus === 'SOK') {
            //근무확정
            return '근무확정이 되었으나 합격확인을 하지 않았습니다.'
          }
          if (this.requestDetail.pushStatus === 'RSOK') {
            //합격확인o
            return '최종 합격 하였습니다.'
          }
          if (this.requestDetail.pushStatus === 'CANCEL') {
            //취소
            return '근무 확정 또는 합격을 취소 하였습니다.'
          } else {
            return PUSH_STATUS_NAME[this.pushStatus]
          }
        } else {
          //근무요청 상태와 근무확정 상태에서는 그 다음 상태를 버튼으로 활성화 한다.
          if (this.requestDetail.pushStatus === 'SCALL') {
            return '지원이 가능 합니다.'
          }
          if (this.requestDetail.pushStatus === 'ROK') {
            return '업체의 합격을 기다리고 있습니다.'
          }
          if (this.requestDetail.pushStatus === 'SOK') {
            return '근무확정이 되었습니다. 합격 확인을 클릭해주세요.'
          }
          if (this.requestDetail.pushStatus === 'RSOK') {
            return '최종 확정 되었습니다. 근무일자에 출근 바랍니다.'
          }
          if (this.requestDetail.pushStatus === 'CANCEL') {
            return '근무 확정 또는 합격을 취소 하였습니다.'
          } else {
            //합격확인 = 합격확인
            return PUSH_STATUS_NAME[this.pushStatus]
          }
        }
      }
    },
  },
  methods: {
    async fetchJobDetail() {
      const res = await getRecruitDetail({
        documentNo: this.$route.params.id,
      })
      //console.info('res', res)
      this.setStateValues(res)
      this.schCompanyId = res.companyId
      this.fetchGeneralList()
    },
    goList() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.moveToPage('/request')
      }
    },
    clickResponse() {
      this.$emit('response')
    },
    clickCancel() {
      this.$emit('cancel')
    },
    clickCheckPass() {
      this.$emit('checkPass')
    },
    goWorkingDetailPage() {
      this.moveToPage('/job-detail/' + this.requestDetail.documentNo)
    },
  },
  created() {
    console.log(this.requestDetail.pushStatus)
  },
}
</script>

<style></style>
