<template>
  <div>
    <!-- 구직자 근무요청 상세 -->
    <job-request-detail-employee
      v-if="isEmployee"
      :requestDetail="requestDetailObject"
      @response="onResponse"
      @cancelRequest="onCancelRequest"
      @checkPass="onCheckPass"
    ></job-request-detail-employee>
    <!-- 구인자 근무요청 상세 -->
    <job-request-detail-employer
      v-else
      :requestDetail="requestDetailObject"
      @ok="onOk"
      @cancelRequest="onCancelRequest"
    ></job-request-detail-employer>
  </div>
</template>

<script>
import JobRequestDetailEmployee from '../partials/JobRequestDetailEmployee'
import JobRequestDetailEmployer from '../partials/JobRequestDetailEmployer'
import { getRequestStatus, putRequestStatus } from 'Api/modules'
import { PUSH_STATUS } from 'Configs/pushStatus'

export default {
  components: {
    JobRequestDetailEmployee,
    JobRequestDetailEmployer,
  },
  data() {
    return {
      pushId: '',
      requestDetailObject: {},
    }
  },
  methods: {
    //구직자 근무응답
    goBackPage() {
      this.$router.go(-1)
    },
    async onResponse() {
      // const res = await putRequestStatus({
      await putRequestStatus({
        pushId: this.pushId,
        userId: this.account.userId,
        senderId: this.account.userId,
        pushStatus: PUSH_STATUS[2].value, //근무응답
        cancelReason: '',
      })
    },

    //구인자 구인 ok 요청(근무확정)
    async onOk() {
      // const res = await putRequestStatus({
      await putRequestStatus({
        pushId: this.pushId,
        userId: this.account.userId,
        senderId: this.account.userId,
        pushStatus: PUSH_STATUS[3].value, //근무확정
        cancelReason: '',
      })
    },

    //구직자 합격확인
    async onCheckPass() {
      // const res = await putRequestStatus({
      await putRequestStatus({
        pushId: this.pushId,
        userId: this.account.userId,
        senderId: this.account.userId,
        pushStatus: PUSH_STATUS[4].value, //합격확인
        cancelReason: '',
      })
    },

    //취소 요청
    async onCancelRequest(cancelReason) {
      try {
        await putRequestStatus({
          pushId: this.pushId,
          userId: this.account.userId,
          senderId: this.account.userId,
          pushStatus: PUSH_STATUS[5].value, //취소
          cancelReason,
        })
        this.$toased.show('근무요청이 취소되었습니다.')
        setTimeout(() => {
          this.$router.go() // 새로고침
        }, 300)
      } catch (error) {
        console.error(error)
      }
    },
  },
  async created() {
    this.pushId = this.$route.params.reqId
    this.requestDetailObject = await getRequestStatus({
      pushId: this.pushId,
    })
  },
}
</script>

<style></style>
