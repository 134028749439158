<template>
  <div class="sub-cont">
    <!-- <div class="yk-req-tit">근무 요청 현황</div> -->
    <!-- <div class="yk-req-searh-wrap">
      <ul>
        <li><a href="javascript:void(0)" class="on">기본정렬순</a></li>
        <li><a href="javascript:void(0)">금액 높은순</a></li>
        <li><a href="javascript:void(0)">최신 등록순</a></li>
      </ul>
      <div class="yk-search-item">
        <select class="select">
          <option>금일 요청만</option>
        </select>
        <input type="text" class="inp" placeholder="검색어" />
        <button type="button"><i class="el-icon-search"></i></button>
      </div>
    </div> -->

    <ul class="work-req-list">
      <!-- v-for -->
      <job-request-item-employee
        :requestDetail="requestDetail"
        @response="onResponse"
        @cancel="onCancel"
        @checkPass="onCheckPass"
      ></job-request-item-employee>
    </ul>

    <!-- <div class="work-nodata">
      <img src="~assets/image/etc/etc-work-nodata.png" />
      <span>근무를 요청한 일자리가 없습니다.</span>
    </div> -->
    <cancel-popup
      :visible.sync="cancelPopupVisible"
      @cancelRequest="onCancelRequest"
    ></cancel-popup>
  </div>
</template>

<script>
import JobRequestItemEmployee from './JobRequestItemEmployee'
import CancelPopup from '../popup/CancelPopup'

export default {
  components: {
    JobRequestItemEmployee,
    CancelPopup,
  },
  props: {
    requestDetail: {
      type: Object,
    },
  },
  data() {
    return {
      cancelPopupVisible: false,
    }
  },
  methods: {
    goBackPage() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.moveToPage('/request')
      }
    },
    onResponse() {
      //this.$toasted.show('요청 받으신 근무를 수락하셨습니다.')
      this.$emit('response')
      this.goBackPage()
    },
    onCheckPass() {
      this.$toasted.show('최종 합격하셨습니다. 근무시작일을 꼭 확인해주세요.')
      this.$emit('checkPass')
      this.goBackPage()
    },
    onCancel() {
      this.cancelPopupVisible = true
    },
    onCancelRequest(cancelReason) {
      this.$emit('cancelRequest', cancelReason)
      this.goBackPage()
    },
  },
}
</script>

<style></style>
