<template>
  <li>
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="info-item-wrap">
      <div class="profile-info-wrap">
        <div class="pic" :style="imgPath"></div>
        <div class="info-header">
          <strong>
            {{ requestDetail.seeker }}
            <!-- 신청자 -->
          </strong>
          <span>
            성별 : {{ requestDetail.gender }} &#47; 나이 :
            {{ requestDetail.age }} 세 &#47; 경력 :
            {{ requestDetail.career }}
            <!-- {{ requestDetail.jobPostTitle }} -->
            <!-- 구인공고명 -->
          </span>
        </div>
        <div class="e01">
          {{
            `${requestDetail.workingPlace} :  ${requestDetail.jobPositionName} - ${requestDetail.jobPositionDtlName}`
          }}
          <!-- 업체명 : 직종 - 룸메이드 -->
        </div>
      </div>

      <dl>
        <dt>근무 매칭 현황</dt>
        <dd>
          <ul class="info-item-list">
            <li v-if="requestDetail.workRequestDt">
              <div class="value">
                <div class="status req">근무요청</div>
                <div class="e01">{{ requestDetail.workRequestDt }}</div>
              </div>
            </li>
            <li v-if="requestDetail.workReplyDt">
              <div class="value">
                <div class="status resp">근무응답</div>
                <div class="e01">{{ requestDetail.workReplyDt }}</div>
              </div>
            </li>
            <li
              v-if="
                requestDetail.pushStatus === PUSH_STATUS[3].value ||
                requestDetail.pushStatus === PUSH_STATUS[4].value ||
                requestDetail.pushStatus === PUSH_STATUS[5].value
              "
            >
              <div class="value">
                <div class="status ok">근무확정</div>
                <div v-if="requestDetail.recruitOkDt" class="e01">
                  {{ requestDetail.recruitOkDt }}
                </div>
                <!--div v-else class="e01">대기중</div-->
              </div>
            </li>
            <li v-if="requestDetail.passDt">
              <div class="value">
                <div class="status succ">합격확인</div>
                <div class="e01">{{ requestDetail.passDt }}</div>
              </div>
            </li>
            <li v-if="requestDetail.pushStatus === PUSH_STATUS[5].value">
              <div class="value">
                <div class="status succ">취소</div>
                <div class="e01">{{ requestDetail.cancelDt }}</div>
                <div class="e01">{{ requestDetail.cancelReason }}</div>
              </div>
            </li>
          </ul>
        </dd>
        <dt>근무 정보</dt>
        <dd>
          <ul class="info-item-list">
            <li>
              <div class="key">근무기간</div>
              <div class="value">
                <div class="e01">
                  {{ requestDetail.workStartDate }} ~
                  {{ requestDetail.workEndDate }}
                </div>
              </div>
            </li>
            <li>
              <div class="key">근무시간</div>
              <div class="value">
                <div class="e01">
                  {{ requestDetail.workStartTime }} ~
                  {{ requestDetail.workEndTime }}
                </div>
                <button
                  class="status resp"
                  style="margin-left: 20px"
                  @click="goWorkingDetailPage"
                >
                  상세보기
                </button>
              </div>
            </li>
            <li>
              <div class="key">마감일시</div>
              <div class="value">
                <div class="e01" style="color: #619ef6">
                  {{ requestDetail.closingDt }}
                </div>
              </div>
            </li>
          </ul>
        </dd>
      </dl>
    </div>
    <div class="info-btn-wrap">
      <!-- 구직회원이 근무요청에 근무응답한 상태에서 구인확정 버튼 활성화 -->
      <button
        type="button"
        class="btn-ok"
        v-if="
          requestDetail.pushStatus === PUSH_STATUS[2].value &&
          requestDetail.negoYn === 'Y' //&&
          //requestDetail.recruitingStatus === 'RECRUITING'
        "
        @click="clickOk"
      >
        구인 확정
      </button>
      <button
        v-if="requestDetail.pushStatus !== PUSH_STATUS[5].value"
        type="button"
        class="btn-cancel"
        @click="clickCancel"
      >
        취소
      </button>
      <button type="button" class="btn-cancel" @click="goList">목록</button>
    </div>
  </li>
</template>

<script>
import { PUSH_STATUS } from 'Configs/pushStatus'
import jobDetailMixin from 'Mixins/jobDetail'
import { URL } from 'Api/api'
import { mapGetters } from 'vuex'

export default {
  mixins: [jobDetailMixin],
  props: {
    requestDetail: {
      type: Object,
    },
  },
  data() {
    return {
      PUSH_STATUS: Object.freeze(PUSH_STATUS),
    }
  },
  methods: {
    goList() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.moveToPage('/request')
      }
    },
    clickOk() {
      //모집인원 <= 합격자
      if (this.requestDetail.positionCnt <= this.requestDetail.passCnt) {
        this.$confirm({
          message:
            `모집 인원 보다 확정 인원이 더 많습니다.` +
            `\n` +
            `구인확정 처리하시겠습니까?`,
          button: {
            yes: '예',
            no: '아니오',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              // ... do something
              // alert('구인 인원', this.positionCnt)
              // alert('근무 확정 인원', this.passCnt)
              this.$emit('ok')
            }
          },
        })
      } else {
        this.$emit('ok')
      }
    },
    clickCancel() {
      this.$emit('cancel')
    },
    goWorkingDetailPage() {
      this.moveToPage('/job-detail/' + this.requestDetail.documentNo)
    },
  },
  computed: {
    ...mapGetters(['accountUserImg']),
    imgPath() {
      // 회사 이미지 필요
      if (this.requestDetail.photo)
        return `background : url(${URL}/api/hgc/file/view/${this.requestDetail.photo})`
      else return require('assets/image/etc/etc-hotel-noimg.svg')
    },
  },
  beforeUpdate: function () {
    if (this.requestDetail.career == 0) {
      this.requestDetail.career = '신입'
    } else {
      this.requestDetail.career = '경력직'
    }
    if (this.requestDetail.gender == 0) {
      this.requestDetail.gender = '남자'
    } else {
      this.requestDetail.gender = '여자'
    }
  },
}
</script>

<style scoped>
.pic {
  background: url(~assets/image/etc/etc-profile01.jpg) no-repeat center center;
}
</style>
