<template>
  <div class="sub-cont">
    <!-- <div class="yk-req-tit">근무 요청 현황</div> -->
    <div class="yk-req-searh-wrap type01">&nbsp;</div>

    <ul class="work-req-list">
      <!-- v-for -->
      <job-request-item-employer
        :requestDetail="requestDetail"
        @ok="onOk"
        @cancel="onCancel"
      ></job-request-item-employer>
    </ul>

    <!-- <div class="work-nodata">
      <img src="~assets/image/etc/etc-work-nodata.png" />
      <span>근무를 요청한 일자리가 없습니다.</span>
    </div> -->
    <cancel-popup
      :visible.sync="cancelPopupVisible"
      @cancelRequest="onCancelRequest"
    ></cancel-popup>
  </div>
</template>

<script>
import JobRequestItemEmployer from '../partials/JobRequestItemEmployer'
import CancelPopup from '../popup/CancelPopup'

export default {
  components: {
    JobRequestItemEmployer,
    CancelPopup,
  },
  props: {
    requestDetail: {
      type: Object,
    },
  },
  data() {
    return {
      cancelPopupVisible: false,
    }
  },
  methods: {
    goBackPage() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.moveToPage('/request')
      }
    },
    onOk() {
      this.$emit('ok')
      this.goBackPage()
    },
    onCancel() {
      this.cancelPopupVisible = true
    },
    onCancelRequest(cancelReason) {
      this.$emit('cancelRequest', cancelReason)
      this.goBackPage()
    },
  },
}
</script>

<style></style>
